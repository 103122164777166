import LogoCarousel from "components/LogoCarousel";
import NumberCounter from "components/NumberCounter";
import { LOGOS1, LOGOS2, LOGOS3, LOGOS4, LOGOS5 } from "helper";

function IndustryUsers() {
  return (
    <div className="industry">
      <section id="section7">
        <div className="title">INDUSTRY USERS</div>
        <div className="description">
          Introducing SiteGenie
        </div>
        {/*
        <div className="card-group">
          <div className="card">
            <div className="open-quote">
              <img
                src="./img/testimonials/open-quote.svg"
                className="w-full h-full"
                alt="open-quote"
              />
            </div>
            <div className="close-quote">
              <img
                src="./img/testimonials/close-quote.svg"
                className="w-full h-full"
                alt="close-quote"
              />
            </div>
            <div className="text">
              "Finally, someone to help me with Paperwork"
            </div>
            <div className="user">
              <div className="avatar">
                <img src="./img/testimonials/avatar.png" alt="avatar" />
              </div>
              <div className="intro">Fabio C, Drywaller, Ajax, ON</div>
            </div>
          </div>

          <div className="card">
            <div className="open-quote">
              <img
                src="./img/testimonials/open-quote.svg"
                className="w-full h-full"
                alt="open-quote"
              />
            </div>
            <div className="close-quote">
              <img
                src="./img/testimonials/close-quote.svg"
                className="w-full h-full"
                alt="close-quote"
              />
            </div>
            <div className="text">
              "This app got it right, no more data entry."
            </div>
            <div className="user">
              <div className="avatar">
                <img src="./img/testimonials/avatar.png" alt="avatar" />
              </div>
              <div className="intro">Bowen H, Project Manager, Toronto, ON</div>
            </div>
          </div>

          <div className="card">
            <div className="open-quote">
              <img
                src="./img/testimonials/open-quote.svg"
                className="w-full h-full"
                alt="open-quote"
              />
            </div>
            <div className="close-quote">
              <img
                src="./img/testimonials/close-quote.svg"
                className="w-full h-full"
                alt="close-quote"
              />
            </div>
            <div className="text">"The assistant I've always wanted."</div>
            <div className="user">
              <div className="avatar">
                <img src="./img/testimonials/avatar.png" alt="avatar" />
              </div>
              <div className="intro">
                Helya M, Project Coordinator, Toronto, ON
              </div>
            </div>
          </div>

          <div className="card">
            <div className="open-quote">
              <img
                src="./img/testimonials/open-quote.svg"
                className="w-full h-full"
                alt="open-quote"
              />
            </div>
            <div className="close-quote">
              <img
                src="./img/testimonials/close-quote.svg"
                className="w-full h-full"
                alt="close-quote"
              />
            </div>
            <div className="text">
              "Finally, someone to help me with Paperwork"
            </div>
            <div className="user">
              <div className="avatar">
                <img src="./img/testimonials/avatar.png" alt="avatar" />
              </div>
              <div className="intro">Fabio C, Drywaller, Ajax, ON</div>
            </div>
          </div>

          <div className="card">
            <div className="open-quote">
              <img
                src="./img/testimonials/open-quote.svg"
                className="w-full h-full"
                alt="open-quote"
              />
            </div>
            <div className="close-quote">
              <img
                src="./img/testimonials/close-quote.svg"
                className="w-full h-full"
                alt="close-quote"
              />
            </div>
            <div className="text">"The assistant I've always wanted."</div>
            <div className="user">
              <div className="avatar">
                <img src="./img/testimonials/avatar.png" alt="avatar" />
              </div>
              <div className="intro">
                Helya M, Project Coordinator, Toronto, ON
              </div>
            </div>
          </div>

          <div className="card">
            <div className="open-quote">
              <img
                src="./img/testimonials/open-quote.svg"
                className="w-full h-full"
                alt="open-quote"
              />
            </div>
            <div className="close-quote">
              <img
                src="./img/testimonials/close-quote.svg"
                className="w-full h-full"
                alt="close-quote"
              />
            </div>
            <div className="text">
              "Finally, someone to help me with Paperwork"
            </div>
            <div className="user">
              <div className="avatar">
                <img src="./img/testimonials/avatar.png" alt="avatar" />
              </div>
              <div className="intro">Fabio C, Drywaller, Ajax, ON</div>
            </div>
          </div>

          <div className="card">
            <div className="open-quote">
              <img
                src="./img/testimonials/open-quote.svg"
                className="w-full h-full"
                alt="open-quote"
              />
            </div>
            <div className="close-quote">
              <img
                src="./img/testimonials/close-quote.svg"
                className="w-full h-full"
                alt="close-quote"
              />
            </div>
            <div className="text">
              "Finally, someone to help me with Paperwork"
            </div>
            <div className="user">
              <div className="avatar">
                <img src="./img/testimonials/avatar.png" alt="avatar" />
              </div>
              <div className="intro">Fabio C, Drywaller, Ajax, ON</div>
            </div>
          </div>

          <div className="card">
            <div className="open-quote">
              <img
                src="./img/testimonials/open-quote.svg"
                className="w-full h-full"
                alt="open-quote"
              />
            </div>
            <div className="close-quote">
              <img
                src="./img/testimonials/close-quote.svg"
                className="w-full h-full"
                alt="close-quote"
              />
            </div>
            <div className="text">
              "This app got it right, no more data entry."
            </div>
            <div className="user">
              <div className="avatar">
                <img src="./img/testimonials/avatar.png" alt="avatar" />
              </div>
              <div className="intro">Bowen H, Project Manager, Toronto, ON</div>
            </div>
          </div>
        </div>
        <div className="title">TESTIMONIALS</div>
        */}
        <div className="content">
          <div className="text1">
            Our team is working hand in hand with many construction industry thought leaders and businesses to bring AI solutions to those who need them the most.
          </div>
          <div className="text2">
            Some of our early adopters are from the following companies and associations:
          </div>
        </div>
        <br />
        <LogoCarousel logos={LOGOS1} />
        <br />
        {/*
        <LogoCarousel logos={LOGOS2} />
        <br />
        <LogoCarousel logos={LOGOS3} />
        <br />
        <LogoCarousel logos={LOGOS4} />
        <br />
        <LogoCarousel logos={LOGOS5} />
        <br />
        */}
        <div className="contentCounter">
          <div className="text">
            +45 More Construction SME Companies and Counting since June 2024
          </div>
          <div className="counterShow">
            <NumberCounter end={45} duration={10} />
          </div>
        </div>
        <div className="video">
          <video controls className="w-full h-full">
            <source src="./video/industry/SIteGenieDoodlyVideo.mp4" type="video/mp4"/>
          </video>
        </div>
      </section>
    </div>
  );
}

export default IndustryUsers;
